@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@100;400&display=swap');

.App {
  position: relative;
  min-height: 100vh;
}

.page {
  padding: 5%;
  padding-bottom: 11%;
  background: radial-gradient(circle, #fcfcfc 0, #e2e2e2 100%);
  min-height: 100vh;
}

.content-container {
  background-color: white;
  padding: 2%;
  border-radius: 8px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.page-content-container {
  max-width: 750px;
  margin: 0 auto;
}

.flex-container--space-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container--space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-container--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container--column {
  display: flex;
  flex-direction: column;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.roboto {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.verdana {
  font-family: 'Verdana', 'Geneva', 'Tahoma', sans-serif;
}

.center {
  text-align: center;
}

.go-back-btn {
  background-color: #9a069a !important;
  color: white !important;
  margin: 2% 0 !important;
}

.restaurant-list__header {
  padding: 0 2% 1%;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.restaurant-list__header--left {
  width: 40%;
}

.restaurant-list__header--middle,
.restaurant-list__header--right {
  text-align: center;
  width: 30%;
}
