.terms-content-container {
  font-size: 0.7rem;
  text-align: justify;
  padding: 5% !important;
}

.terms-content-container h2,
.terms-content-container h3 {
  text-align: center;
}
