@import url('https://fonts.googleapis.com/css?family=Bowlby+One+SC&display=swap&text=DNPQXoms');

header {
  width: 101%;
}
.header-top {
  background-color: #0089ff;
  width: 100%;
  height: 40px;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-top__greeting {
  color: white;
  font-weight: bold;
  margin-right: 3%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5%;
}
.brand {
  display: flex;
  font-size: 25px;
  align-items: center;
}
.burger {
  font-size: 35px;
}
.brand h1 {
  font-size: 18px;
  font-family: 'Bowlby One SC', 'Helvetica', 'Arial', sans-serif;
  text-shadow: -2px 2px 1px #e71d36;
  color: white;
}
@media screen and (min-width: 650px) {
  .brand h1 {
    font-size: 30px;
  }
}
.header-bottom {
  background-color: #0069c3;
  width: 100%;
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
}
.header-bottom nav {
  width: 100%;
  margin-right: 3%;
}
nav ul {
  font-weight: 500;
  line-height: 1.75;
  font-size: 0.8rem;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav__link {
  margin-right: 35px;
}
@media screen and (max-width: 320px) {
  .nav__link {
    margin-right: 15px;
  }
}
.nav__link:last-child {
  margin-right: 5px;
}
.nav__link--active {
  border-bottom: 2px solid rgb(216, 67, 67);
  transition: 0.2s linear;
}
@media screen and (min-width: 950px) {
  .nav__links {
    margin-right: 10%;
  }
  .header-top__greeting {
    margin-right: 3%;
  }
}
