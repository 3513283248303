.add-new-nom-btn {
  height: 25px;
  border-radius: 10px;
  background-color: #00bb00;
  color: white;
}
.add-new-nom-btn:hover {
  cursor: pointer;
  box-shadow: 1px 1px 2px black;
}
.pre-footer {
  margin-top: 20vh;
}
.pre-footer ul {
  width: 100vw;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}
.pre-footer ul li a {
  text-decoration: none;
  color: rgb(97, 97, 97);
  letter-spacing: 1.2px;
  font-variant: small-caps;
  font-stretch: ultra-condensed;
}
