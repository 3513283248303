footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #011627;
  color: white;
  text-align: right;
  padding: 0.5% 3%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8rem;
}

.footer__link {
  text-decoration: none;
  margin-left: 1%;
  color: white;
}
