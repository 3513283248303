.buttons-container {
  height: 50px;
}
.vote-btn {
  height: 100%;
  font-weight: bold;
  border-radius: 10px;
}
.vote-btn:hover {
  cursor: pointer;
  box-shadow: 1px 1px 2px black;
}
.upvote-btn {
  background-color: #707070;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 25px;
}
.upvoted-btn {
  background-color: #0089ff;
  border: 2px solid blue;
  font-size: 50px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.55), 0 1px 2px rgba(0, 0, 0, 0.55);
  animation: upvoted 0.1s linear;
}

@keyframes upvoted {
  0% {
    transform: scale(0.8);
    box-shadow: 0px 0px 0px #015dada9;
  }
  85% {
    transform: scale(1.2);
    box-shadow: 3px 3px 3px #0089ff;
  }
  100% {
    transform: scale(1);
    box-shadow: 1px 1px 1px #0089ff;
  }
}
