.comment-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 8px;
  border: 0.5px solid rgb(179, 179, 179);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.22);
}
.comment-card__user {
  text-align: right;
}
