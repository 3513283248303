.user-info {
  text-align: right;
}
.user-info h4 {
  font-size: 1.5em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 2%;
}
.tab-title {
  font-size: 1.1em;
  margin: 2%;
}
.profile-page__list-item {
  border-bottom: 1px solid grey;
  padding: 2%;
}
.profile-page__list-item-title-container {
  width: 50%;
}
.profile-page__list-item--title {
  font-size: 1em;
}
.profile-page__list-item--title {
  font-size: 0.9em;
}
.profile-page__list-item--category,
.profile-page__list-item--vote-count {
  font-size: 0.85em;
}
.profile-page__list-item--quote {
  background-color: rgb(235, 253, 253);
  border-radius: 4px;
  padding: 1% 3%;
  font-size: 0.8em;
  width: 96%;
  margin: 0 auto 2%;
}
.profile-page__list-item--quote-header {
  font-size: 0.8em;
}
