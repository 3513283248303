.restaurant-preview-card {
  border-radius: 7px;
  margin-bottom: 2%;
  padding: 2%;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.55), 0 1px 2px rgba(0, 0, 0, 0.55);
  display: flex;
}
.restaurant-preview-card--left {
  width: 40%;
}
.restaurant-preview-card--right,
.restaurant-preview-card--middle {
  width: 30%;
}
.restaurant-preview-card h5 {
  margin: 0;
}
.hide {
  display: none;
}
.upvote-animation {
  animation: fade-out-bg-color 1s;
}
@keyframes fade-out-bg-color {
  from {
    background-color: rgb(248, 130, 238);
  }
  to {
    background-color: white;
  }
}
