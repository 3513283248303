.about-page__content-container {
  padding: 5% !important;
}
.about-page__content {
  margin-top: 4%;
  line-height: 1.3;
}
.about-page__title {
  font-size: 2em;
  padding-bottom: 2%;
}
