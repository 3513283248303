@import url(https://fonts.googleapis.com/css?family=Bowlby+One+SC&display=swap&text=DNPQXoms);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@100;400&display=swap);
.skeleton {
  position: relative;
  overflow: hidden;
  height: 500px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.skeleton::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  animation: sweep 2s infinite;
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
}

.skeleton__fake-button {
  /* background-color: rgb(66, 133, 244); */
  position: relative;
  overflow: hidden;
  height: 50px;
  width: 240px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
  line-height: 48px;
  display: block;
  border-radius: 1px;
  background-image: linear-gradient(
    to right,
    rgb(238, 243, 252),
    rgb(104, 159, 248),
    rgb(66, 133, 244)
  );
}

@keyframes sweep {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.skeleton__title {
  height: 75px;
  width: 100%;
  border-radius: 3px;
  background-color: rgba(116, 116, 116, 0.3);
}
.skeleton__subsection {
  height: 45px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(199, 199, 199, 0.3);
}

.menuList-container {
  padding: 5%;
  max-height: 85vh;
  overflow-y: scroll;
}

header {
  width: 101%;
}
.header-top {
  background-color: #0089ff;
  width: 100%;
  height: 40px;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-top__greeting {
  color: white;
  font-weight: bold;
  margin-right: 3%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 5%;
}
.brand {
  display: flex;
  font-size: 25px;
  align-items: center;
}
.burger {
  font-size: 35px;
}
.brand h1 {
  font-size: 18px;
  font-family: 'Bowlby One SC', 'Helvetica', 'Arial', sans-serif;
  text-shadow: -2px 2px 1px #e71d36;
  color: white;
}
@media screen and (min-width: 650px) {
  .brand h1 {
    font-size: 30px;
  }
}
.header-bottom {
  background-color: #0069c3;
  width: 100%;
  height: 30px;
  color: white;
  display: flex;
  align-items: center;
}
.header-bottom nav {
  width: 100%;
  margin-right: 3%;
}
nav ul {
  font-weight: 500;
  line-height: 1.75;
  font-size: 0.8rem;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav__link {
  margin-right: 35px;
}
@media screen and (max-width: 320px) {
  .nav__link {
    margin-right: 15px;
  }
}
.nav__link:last-child {
  margin-right: 5px;
}
.nav__link--active {
  border-bottom: 2px solid rgb(216, 67, 67);
  transition: 0.2s linear;
}
@media screen and (min-width: 950px) {
  .nav__links {
    margin-right: 10%;
  }
  .header-top__greeting {
    margin-right: 3%;
  }
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #011627;
  color: white;
  text-align: right;
  padding: 0.5% 3%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8rem;
}

.footer__link {
  text-decoration: none;
  margin-left: 1%;
  color: white;
}

.buttons-container {
  height: 50px;
}
.vote-btn {
  height: 100%;
  font-weight: bold;
  border-radius: 10px;
}
.vote-btn:hover {
  cursor: pointer;
  box-shadow: 1px 1px 2px black;
}
.upvote-btn {
  background-color: #707070;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 25px;
}
.upvoted-btn {
  background-color: #0089ff;
  border: 2px solid blue;
  font-size: 50px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.55), 0 1px 2px rgba(0, 0, 0, 0.55);
  animation: upvoted 0.1s linear;
}

@keyframes upvoted {
  0% {
    transform: scale(0.8);
    box-shadow: 0px 0px 0px #015dada9;
  }
  85% {
    transform: scale(1.2);
    box-shadow: 3px 3px 3px #0089ff;
  }
  100% {
    transform: scale(1);
    box-shadow: 1px 1px 1px #0089ff;
  }
}

.restaurant-preview-card {
  border-radius: 7px;
  margin-bottom: 2%;
  padding: 2%;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.55), 0 1px 2px rgba(0, 0, 0, 0.55);
  display: flex;
}
.restaurant-preview-card--left {
  width: 40%;
}
.restaurant-preview-card--right,
.restaurant-preview-card--middle {
  width: 30%;
}
.restaurant-preview-card h5 {
  margin: 0;
}
.hide {
  display: none;
}
.upvote-animation {
  animation: fade-out-bg-color 1s;
}
@keyframes fade-out-bg-color {
  from {
    background-color: rgb(248, 130, 238);
  }
  to {
    background-color: white;
  }
}

.category-card {
  margin-top: 5%;
  width: 100%;
  max-width: 400px;
}
.category-card__title {
  margin: 0 5%;
}
.category-card__title--right {
  font-size: 0.8rem;
}
.category-card__list-count {
  font-feature-settings: "smcp";
  font-variant: small-caps;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.add-new-nom-btn {
  height: 25px;
  border-radius: 10px;
  background-color: #00bb00;
  color: white;
}
.add-new-nom-btn:hover {
  cursor: pointer;
  box-shadow: 1px 1px 2px black;
}
.pre-footer {
  margin-top: 20vh;
}
.pre-footer ul {
  width: 100vw;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}
.pre-footer ul li a {
  text-decoration: none;
  color: rgb(97, 97, 97);
  letter-spacing: 1.2px;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  font-stretch: ultra-condensed;
}


.comment-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 8px;
  border: 0.5px solid rgb(179, 179, 179);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.22);
}
.comment-card__user {
  text-align: right;
}

.restaurant-page__container {
  padding: 5% !important;
}
.restaurant-page__container-top-line {
  border-bottom: 2px solid #cccccc;
  margin-bottom: 2%;
}
.restaurant-page__title {
  width: 65%;
  margin-bottom: 2%;
}
.restaurant-page__vote-btns {
  width: 30%;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.restaurant-page__subtitle {
  font-size: 1em;
  margin-bottom: 2%;
}
.restaurant-page__secondary-info {
  height: 100%;
  font-size: 0.7em;
  font-family: 'Verdana', 'Geneva', 'Tahoma', sans-serif;
  margin-bottom: 2%;
}
.restaurant-page__secondary-info--date {
  align-self: flex-end;
}
.restaurant-page__btn-container {
  width: 96%;
  margin: 2%;
}

.form-container--outer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.562);
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-container--inner {
  background-color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  max-height: 400px;
  width: 80%;
  max-width: 400px;
  padding: 5%;
}
.comment-form__buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.add-new-restaurant__container {
  min-height: 400px;
  max-height: 450px;
  max-width: 550px !important;
  justify-content: space-between;
  margin: 0 auto;
  padding: 50px !important;
}
.add-new-restaurant__title {
  min-height: 60px;
}
.add-new-restaurant__subtitle {
  margin: 5% 0 5% 1%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.create-form__fields {
  width: 80%;
  margin: 0 auto;
}
#city-select-menu {
  font-size: 0.7em;
}
.create-form__fields--inline {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.create-form__zip-field {
  margin-left: 5% !important;
}
.form-buttons__container {
  width: 100%;
  justify-self: flex-end;
  display: flex;
}
.form-buttons__container--submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.category-select__next-btn-container {
  display: flex;
  justify-content: flex-end;
}
.search-form__input-field {
  width: 100%;
}
.result-list-item--api {
  padding: 5px;
  background-color: white;
  border-bottom: 0.5px solid grey;
  transition: 0.1s linear;
  border-radius: 8px;
}
.result-list-item--api:hover {
  background-color: rgb(255, 255, 65);
  cursor: pointer;
}
.result-list__name {
  font-size: 0.85em;
}
.result-list__subtitle {
  font-size: 0.7em;
}
.add-new__list-item {
  padding: 5px 5px 0;
}
.results-container {
  background-color: rgb(236, 235, 235);
  padding: 1%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 0 0 6px 6px;
  position: absolute;
  z-index: 1;
  border-top: none;
}
.form-buttons__container {
  margin-top: 50px;
}
.submit-form-info__container {
  margin: 5%;
}
.submit-form-info__guide-text {
  font-size: 0.8em;
  font-family: 'Montserrat';
}
.submit-form-info__restaurant-title,
.submit-form-info__restaurant-subtitle {
  color: blueviolet;
  font-weight: 600;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.submit-form-info__restaurant-title {
  font-size: 1.05em;
}
.submit-form-info__restaurant-subtitle {
  font-size: 0.9em;
}

.user-info {
  text-align: right;
}
.user-info h4 {
  font-size: 1.5em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 2%;
}
.tab-title {
  font-size: 1.1em;
  margin: 2%;
}
.profile-page__list-item {
  border-bottom: 1px solid grey;
  padding: 2%;
}
.profile-page__list-item-title-container {
  width: 50%;
}
.profile-page__list-item--title {
  font-size: 1em;
}
.profile-page__list-item--title {
  font-size: 0.9em;
}
.profile-page__list-item--category,
.profile-page__list-item--vote-count {
  font-size: 0.85em;
}
.profile-page__list-item--quote {
  background-color: rgb(235, 253, 253);
  border-radius: 4px;
  padding: 1% 3%;
  font-size: 0.8em;
  width: 96%;
  margin: 0 auto 2%;
}
.profile-page__list-item--quote-header {
  font-size: 0.8em;
}

@media screen and (max-width: 570px) {
  .restaurant-review__preview-box {
    margin-top: 5%;
  }
}

.about-page__content-container {
  padding: 5% !important;
}
.about-page__content {
  margin-top: 4%;
  line-height: 1.3;
}
.about-page__title {
  font-size: 2em;
  padding-bottom: 2%;
}

.terms-content-container {
  font-size: 0.7rem;
  text-align: justify;
  padding: 5% !important;
}

.terms-content-container h2,
.terms-content-container h3 {
  text-align: center;
}

.App {
  position: relative;
  min-height: 100vh;
}

.page {
  padding: 5%;
  padding-bottom: 11%;
  background: radial-gradient(circle, #fcfcfc 0, #e2e2e2 100%);
  min-height: 100vh;
}

.content-container {
  background-color: white;
  padding: 2%;
  border-radius: 8px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.page-content-container {
  max-width: 750px;
  margin: 0 auto;
}

.flex-container--space-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flex-container--space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-container--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-container--column {
  display: flex;
  flex-direction: column;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.roboto {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.verdana {
  font-family: 'Verdana', 'Geneva', 'Tahoma', sans-serif;
}

.center {
  text-align: center;
}

.go-back-btn {
  background-color: #9a069a !important;
  color: white !important;
  margin: 2% 0 !important;
}

.restaurant-list__header {
  padding: 0 2% 1%;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.restaurant-list__header--left {
  width: 40%;
}

.restaurant-list__header--middle,
.restaurant-list__header--right {
  text-align: center;
  width: 30%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ul-reset {
  list-style: none;
  margin: 0;
}

