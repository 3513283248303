.form-container--outer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.562);
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-container--inner {
  background-color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  max-height: 400px;
  width: 80%;
  max-width: 400px;
  padding: 5%;
}
.comment-form__buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
