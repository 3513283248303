.add-new-restaurant__container {
  min-height: 400px;
  max-height: 450px;
  max-width: 550px !important;
  justify-content: space-between;
  margin: 0 auto;
  padding: 50px !important;
}
.add-new-restaurant__title {
  min-height: 60px;
}
.add-new-restaurant__subtitle {
  margin: 5% 0 5% 1%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.create-form__fields {
  width: 80%;
  margin: 0 auto;
}
#city-select-menu {
  font-size: 0.7em;
}
.create-form__fields--inline {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.create-form__zip-field {
  margin-left: 5% !important;
}
.form-buttons__container {
  width: 100%;
  justify-self: flex-end;
  display: flex;
}
.form-buttons__container--submit {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.category-select__next-btn-container {
  display: flex;
  justify-content: flex-end;
}
.search-form__input-field {
  width: 100%;
}
.result-list-item--api {
  padding: 5px;
  background-color: white;
  border-bottom: 0.5px solid grey;
  transition: 0.1s linear;
  border-radius: 8px;
}
.result-list-item--api:hover {
  background-color: rgb(255, 255, 65);
  cursor: pointer;
}
.result-list__name {
  font-size: 0.85em;
}
.result-list__subtitle {
  font-size: 0.7em;
}
.add-new__list-item {
  padding: 5px 5px 0;
}
.results-container {
  background-color: rgb(236, 235, 235);
  padding: 1%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 0 0 6px 6px;
  position: absolute;
  z-index: 1;
  border-top: none;
}
.form-buttons__container {
  margin-top: 50px;
}
.submit-form-info__container {
  margin: 5%;
}
.submit-form-info__guide-text {
  font-size: 0.8em;
  font-family: 'Montserrat';
}
.submit-form-info__restaurant-title,
.submit-form-info__restaurant-subtitle {
  color: blueviolet;
  font-weight: 600;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
.submit-form-info__restaurant-title {
  font-size: 1.05em;
}
.submit-form-info__restaurant-subtitle {
  font-size: 0.9em;
}
